<script setup>
import { useAxiosRepo } from '@pinia-orm/axios'
import { api } from '@/plugins/api';
import { Thread } from '@/models';
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { EditIcon, TrashIcon } from 'lucide-vue-next';
import { Button } from '@/components/ui/button';
import { deleteThread, updateThreadName, startEditing, editingId } from '@/utils/threadUtils'; 
import { Input } from '@/components/ui/input';
import { EmptyPlaceholder } from '@/components/library';

const router = useRouter();
const route = useRoute();
const repo = useAxiosRepo(Thread).setAxios(api);

function handleDeleteThread(id) {
  deleteThread(id, route, router);
}

const orderedThreads = computed(() => {
  return repo.all().slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
})

</script>

<template>
    <div v-if="orderedThreads.length > 0" class="space-y-2">
      <div v-for="thread in orderedThreads" :key="thread.id" class="flex flex-row border space-x-2 justify-between rounded-xl bg-backgroundSecondary py-2 px-4 cursor-pointer hover:bg-accent">
          
              <div v-if="editingId === thread.id" class="flex flex-row w-full items-center">
                <Input 
                  v-model="thread.name"
                  @keydown.enter="updateThreadName(thread.id)"
                  @blur="updateThreadName(thread)"
                  class="text-xs font-light w-f h-10 py-0"
                  autofocus
                />
              </div>  
              <div v-else @click="router.push({ name: 'thread', params: { id: thread.id } })" class="flex flex-row w-full items-center">
                <h4 class="text-left text-md font-medium tracking-tight justify-start flex items-center truncate max-w-[200px] md:max-w-[300px]">
                    {{ thread.name.length > 40 ? thread.name.slice(0, 40) + '...' : thread.name }}
                </h4> 
              </div>

          <div class="flex flex-row space-x-2 items-center">
              <Button @click="startEditing(thread.id)" variant="outline" size="icon">
                  <EditIcon class="h-4 w-4" />
              </Button>
              <Button  variant="outline" @click="handleDeleteThread(thread.id)">
                  <TrashIcon class="h-4 w-4" />
              </Button>
          </div>
      </div>
    </div>

   <EmptyPlaceholder v-else />
</template>

