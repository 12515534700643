import addFormats from 'ajv-formats';
import { Model, useRepo } from 'pinia-orm';
import Ajv from 'ajv';
const ajv = new Ajv({ useDefaults: true, removeAdditional: true });
addFormats(ajv);

class References extends Model {
    static entity = 'references'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),  // Change this line
            run_id: this.attr(null),
            source_id: this.attr(null),
            source_type: this.attr(null),
            order: this.attr(null),
            initial_index: this.attr(null),
        }
    }

    setReference(value) {
        this.$update({
            source_id: value.id,
            source_type: value.type,
            order: value.order,
            initial_index: value.initial_index
        });
    }

    static getReferences(runId) {
        const repo = useRepo(this);
        const references = repo.query()
            .where('run_id', runId)
            .orderBy('order')
            .get();
        

        const mappedReferences = references.map(ref => ({
            id: ref.id,
            run_id: ref.run_id,
            source_id: ref.source_id,
            source_type: ref.source_type,
            order: ref.order,
            initial_index: ref.initial_index
        }));

        return mappedReferences;
    }

    static getReferencesGroupedByType(runId) {
        const references = this.getReferences(runId);
        return references.reduce((grouped, ref) => {
            if (!grouped[ref.source_type]) {
                grouped[ref.source_type] = [];
            }
            grouped[ref.source_type].push(ref);
            return grouped;
        }, {});
    }
}

export { References }