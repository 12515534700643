<script setup>
import { ScrollArea } from '@/components/ui/scroll-area'
import { Input } from '@/components/ui/input'
import { useRouter, useRoute } from 'vue-router'
import { Trash2 } from 'lucide-vue-next'
import { Edit } from 'lucide-vue-next'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { useAxiosRepo } from '@pinia-orm/axios'
import { Button } from '@/components/ui/button'
import { Thread } from '@/models';
import { api } from '@/plugins/api';
import { deleteThread, updateThreadName, startEditing, editingId } from '@/utils/threadUtils';
import { useSidebarStore } from '@/stores/sidebar';
import { computed } from 'vue';

const repo = useAxiosRepo(Thread).setAxios(api);
const router = useRouter();
const route = useRoute();

function cls(id) {
  let c = 'w-full hover:text-primary justify-between'
  if (route.params?.id === id) c += ' bg-accent text-primary '
  return c
}

async function handleDeleteThread(id) {
  // await repo.api().get('/threads/', { params: { limit: 1, offset: 15 } })
  deleteThread(id, route, router);
}

const sidebarStore = useSidebarStore();
function handleClickThread(id) {
  if (!sidebarStore.isLargeScreen() && sidebarStore.isSidebarOpen) {
    sidebarStore.toggleSidebar();
  }
  router.push({ name: 'thread', params: { id: id } })
}

const orderedThreads = computed(() => {
  return repo.all().slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
})
</script>
<template>

  <ScrollArea class="h-[calc(100dvh-420px)]">
    <div class="space-y-1 pt-1 pl-5 px-2 items-left w-[100%] text-primary/70">
      <div class="flex flex-row space-x-2 h-9 px-3 py-2 rounded-md cursor-pointer hover:bg-accent" size="sm"
        v-for="thread in orderedThreads" :key="thread.id" variant="ghost"
        :class="cls(thread.id)">

        <div @click="handleClickThread(thread.id)"
          class="flex flex-row w-full items-center justify-start">
          <!-- Threads icon small -->
          <svg class="mr-2" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.75432 1.81954C7.59742 1.72682 7.4025 1.72682 7.24559 1.81954L1.74559 5.06954C1.59336 5.15949 1.49996 5.32317 1.49996 5.5C1.49996 5.67683 1.59336 5.84051 1.74559 5.93046L7.24559 9.18046C7.4025 9.27318 7.59742 9.27318 7.75432 9.18046L13.2543 5.93046C13.4066 5.84051 13.5 5.67683 13.5 5.5C13.5 5.32317 13.4066 5.15949 13.2543 5.06954L7.75432 1.81954ZM7.49996 8.16923L2.9828 5.5L7.49996 2.83077L12.0171 5.5L7.49996 8.16923ZM2.25432 8.31954C2.01658 8.17906 1.70998 8.2579 1.56949 8.49564C1.42901 8.73337 1.50785 9.03998 1.74559 9.18046L7.24559 12.4305C7.4025 12.5232 7.59742 12.5232 7.75432 12.4305L13.2543 9.18046C13.4921 9.03998 13.5709 8.73337 13.4304 8.49564C13.2899 8.2579 12.9833 8.17906 12.7456 8.31954L7.49996 11.4192L2.25432 8.31954Z"
              fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
          </svg>
          <!-- End threads icon small -->
          <div v-if="editingId === thread.id">
            <Input v-model="thread.name" @keydown.enter="updateThreadName(thread)" @blur="updateThreadName(thread)"
              class="text-xs font-light w-[130px] h-6 py-0" autofocus />
          </div>
          <p v-else class="text-xs font-light truncate max-w-[130px]">
            {{ thread.name }}
          </p>
        </div>
        <Popover>
          <PopoverTrigger>
            <Button class="hover:text-secondary" size="xs" variant="ghost">
              <svg class="font-semibold " width="20" height="20" viewBox="0 0 15 15" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.625 7.5C3.625 8.12132 3.12132 8.625 2.5 8.625C1.87868 8.625 1.375 8.12132 1.375 7.5C1.375 6.87868 1.87868 6.375 2.5 6.375C3.12132 6.375 3.625 6.87868 3.625 7.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM12.5 8.625C13.1213 8.625 13.625 8.12132 13.625 7.5C13.625 6.87868 13.1213 6.375 12.5 6.375C11.8787 6.375 11.375 6.87868 11.375 7.5C11.375 8.12132 11.8787 8.625 12.5 8.625Z"
                  fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
              </svg>
            </Button>
          </PopoverTrigger>
          <PopoverContent class="w-[150px]">

            <Button variant="ghost" size="sm" class="w-full justify-start space-x-2"
              @click="handleDeleteThread(thread.id)">
              <div class="flex flex-row items-center space-x-2">
                <Trash2 class="w-3 h-3" />
                <p class="text-sm">Verwijderen</p>
              </div>
            </Button>
            <Button variant="ghost" size="sm" class="w-full justify-start space-x-2" @click="startEditing(thread.id)">
              <div class="flex flex-row items-center space-x-2">
                <Edit class="w-3 h-3" />
                <p class="text-sm">Bewerken</p>
              </div>
            </Button>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  </ScrollArea>

</template>