import { Paralegal } from '@/models/Paralegal';
import addFormats from 'ajv-formats';
import { Model } from 'pinia-orm';
import Ajv from 'ajv';

const ajv = new Ajv({ useDefaults: true, removeAdditional: true });
addFormats(ajv);

class ModelRun extends Model {
    static entity = 'runs'
    static fields() {
        return {
            id: this.attr(null),
            thread_id: this.attr(null),
            status: this.string('running'),
            plan: this.attr(null),
            query: this.string(null),
            sources: this.attr(null),
            referenced_sources: this.attr(null),
            answer: this.attr(null),
            suggested_follow_ups: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            thread: this.belongsTo(Thread, "thread_id")
        }
    }
}

class Thread extends Model {
    static entity = 'threads'
    static fields() {
        return {
            id: this.attr(null),
            name: this.string('Untitled'),
            paralegal_id: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            paralegal: this.belongsTo(Paralegal, 'paralegal_id'),
            runs: this.hasMany(ModelRun, 'thread_id')
        }
    }

    get isLocked() {
        return this.runs.filter(r => r.status === 'running').length > 0;
    }
}

export { Thread, ModelRun }
