<script setup>
import SideBar from '@/components/sidebar/SideBar.vue';
import TopMenu from '@/components/topmenu/TopMenu.vue';
import { RouterView } from 'vue-router';
import { Toaster } from '@/components/ui/sonner';
import { useMediaQuery } from '@vueuse/core';
import { useSidebarStore } from '@/stores/sidebar';
import { useAuth } from '@/auth';
import { computed, watch} from 'vue';
import { useData } from '@/composables';
//import MaintenancePage from '@/views/MaintenancePage.vue';
const auth = useAuth();
const { isLoading } = useData();
const sidebarStore = useSidebarStore();
const isLargeScreen = useMediaQuery('(min-width: 1024px)');
const sidebarVisibility = computed(() => {
  if (isLargeScreen.value) {
    return sidebarStore.isSidebarOpen ? 'visible w-60' : 'hidden';
  }
  return sidebarStore.isSidebarOpen ? 'visible' : 'hidden';
});

const mainContentWidth = computed(() => {
  if (isLargeScreen.value) {
    return sidebarStore.isSidebarOpen ? 'w-[calc(100vw-15rem)] ml-60' : 'w-screen';
  }
  return 'w-screen';
});

watch(isLargeScreen, (isLarge) => {
  sidebarStore.isSidebarOpen = isLarge;
}, { immediate: true });


</script>
<template>
  <!-- <MaintenancePage v-if="isUnderMaintenance" /> -->
  <section v-if="auth.isAuthenticated && !auth.isLoading && !isLoading">
    <div>
      <div class="flex flex-row bg-background w-screen">
        <SideBar 
          :class="[
            'fixed h-screen bg-backgroundSecondary transition-all border-r border-border duration-200 z-50',
            sidebarVisibility,
            { 'w-60': isLargeScreen, 'w-60 shadow-lg': !isLargeScreen }
          ]" 
          :isSidebarOpen="sidebarStore.isSidebarOpen" 
        />
        <div :class="[mainContentWidth, 'grid grid-cols-6 transition-all h-screen bg-background duration-50']">
          <TopMenu
            class="h-[60px] col-span-6 bg-background z-10"
            :isSidebarOpen="sidebarStore.isSidebarOpen"
          />
          <RouterView :class="'h-[calc(100vh-60px)] bg-background col-span-6'"/>
        </div>
      </div>
    </div>
    <Toaster />
  </section>
  <div v-else class="flex items-center bg-background justify-center min-h-screen">
    <i class='bx bxs-circle bx-burst bx-lg text-primary'></i>
  </div>
</template>

<style>
@import 'css/tailwind/tailwind.min.css';

#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: hsl(var(--primary));
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Satoshi', sans-serif;
  color: hsl(var(--primary));
}

p, span {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
</style>