<script setup>
import { Button } from '@/components/ui/button'
import { defineProps } from 'vue';
import { deleteThread } from '@/utils/threadUtils'; 
import { useRouter, useRoute } from 'vue-router';

const props = defineProps({
  thread: {
    type: Object,
    required: true
  }
});

// const emit = defineEmits(['update:title', 'share', 'delete'])

// const updateTitle = (event) => {
//   emit('update:title', event.target.value)
// }

const router = useRouter();
const route = useRoute();
function handleDeleteThread(id) {
  deleteThread(id, route, router);
}

</script>

<template>
  <div class="w-full">
    <div class="flex items-center justify-between">
      <div class="flex-grow flex flex-row space-x-2 border text-primary bg-background border-border h-12 items-center px-4 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-bot-message-square">
                  <path d="M12 6V2H8" />
                  <path d="m8 18-4 4V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z" />
                  <path d="M2 12h2" />
                  <path d="M9 11v2" />
                  <path d="M15 11v2" />
                  <path d="M20 12h2" />
                </svg>
        <p class="text-sm">
            {{ props.thread.paralegal?.name || 'Undefined' }}
        </p>
      </div>
      <Button variant="outline" class="ml-2 h-12" @click="handleDeleteThread(props.thread.id)">
        <svg class="" width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 1C5.22386 1 5 1.22386 5 1.5C5 1.77614 5.22386 2 5.5 2H9.5C9.77614 2 10 1.77614 10 1.5C10 1.22386 9.77614 1 9.5 1H5.5ZM3 3.5C3 3.22386 3.22386 3 3.5 3H5H10H11.5C11.7761 3 12 3.22386 12 3.5C12 3.77614 11.7761 4 11.5 4H11V12C11 12.5523 10.5523 13 10 13H5C4.44772 13 4 12.5523 4 12V4L3.5 4C3.22386 4 3 3.77614 3 3.5ZM5 4H10V12H5V4Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
      </Button>
    </div>
  </div>
</template>