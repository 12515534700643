<script setup>
import Suggestions from '@/components/runs/Suggestions'
import { ReferenceList } from '@/components/sources'
import Progress from '@/components/runs/Progress'
import Title from '@/components/runs/Title'
import Answer from '@/components/runs/Answer'
import { computed, defineProps, watch, defineEmits, ref, onMounted, nextTick, toRefs, watchEffect } from 'vue'
import { References } from '@/models/References'
import { useElementSize } from '@vueuse/core';
import {  Asterisk } from 'lucide-vue-next'
import { useSearch } from '@/composables'

const props = defineProps(['i', 'run', 'threadId', 'isLast'])
const { i, run, threadId, isLast } = toRefs(props)
const emits = defineEmits(['changing', 'sizing']);
const el = ref(null);
const { height } = useElementSize(el);
const { text, sendMessage } = useSearch('thread', threadId.value)

const progress = computed(() => {
    if (run.value?.suggested_follow_ups?.length > 0) return 3;
    if (run.value?.answer) return 2;
    if (run.value?.sources?.cases?.length > 0 || run.value.sources?.consolidations?.length > 0) return 1;
    return 0;
})

const references = ref([])

onMounted(() => {
    emits('sizing', i, height.value)
})

watch(run, () => {
    emits('changing')
    emits('sizing', i, height.value)
})

const cls = computed(() => {
    let c = ''
    if (isLast.value) c += ' min-h-screen bg-background mb-5'
    return c
})
const scrollContainer = ref(null);
const scrollIntoView = () => {
    nextTick(() => {
        if (scrollContainer.value && isLast && progress.value <= 3) {
            scrollContainer.value.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    });
};

onMounted(
    () => {
        if (progress.value <= 2) {
            scrollIntoView();
        }

    }
)

const filteredReferences = computed(() => {
  const referencedCaseIds = new Set(run.value?.referenced_sources?.cases || []);
  const referencedConsolidationIds = new Set(run.value?.referenced_sources?.consolidations || []);
  
  const filteredCases = (run.value?.sources?.cases || []).filter(source => 
    referencedCaseIds.has(source.id)
  );
  
  const filteredConsolidations = (run.value?.sources?.consolidations || []).filter(source => 
    referencedConsolidationIds.has(source.id)
  );

  return [...filteredCases, ...filteredConsolidations];
});


watchEffect(() => {
  if (run.value?.id) {
    references.value = References.getReferences(run.value.id)
  }
});

const referencesWithSources = computed(() => {
  if (!filteredReferences.value || !references.value) return [];

  const sourceIdMap = new Map(
    filteredReferences.value.map(source => [source.id, source])
  );

  const uniqueReferences = new Map();

  references.value
    .filter(reference => sourceIdMap.has(reference.source_id))
    .forEach(reference => {
      const key = reference.source_id;
      if (!uniqueReferences.has(key) || reference.order < uniqueReferences.get(key).order) {
        uniqueReferences.set(key, {
          ...reference,
          source: sourceIdMap.get(reference.source_id)
        });
      }
    });

  return Array.from(uniqueReferences.values()).sort((a, b) => a.order - b.order);
});

const refList = computed(() => {
  return referencesWithSources.value.map(ref => ref.source);
});

</script>

<template>
    <div ref="scrollContainer" :class="cls">
        
        <div class="grid grid-cols-12 gap-0">
            <Title class="text-primary title-ref my-6 col-span-12 sm:col-span-8" :question="run?.query" />
        </div>
        
        <div class="grid grid-cols-12 gap-0">
            
            <div class="sm:col-span-8 col-span-12 pr-4">
                <Progress class="mb-5" :status="run?.status" :progress="progress" :plan="run?.plan" />
                <Answer 
                    :show="progress >= 2" 
                    :answer="run?.answer" 
                    :sources="run?.sources" 
                    :runId="run?.id" 
                    :status="run?.status" 
                    :filteredReferences="filteredReferences"
                    :sendMessage="sendMessage"
                />
                <Suggestions v-if="isLast && (run?.status === 'success')" v-model="text" :sendMessage="sendMessage"
                :suggestions="run?.suggested_follow_ups" />
            </div>
            
            <div class="col-span-4 ml-4 relative sm:block hidden">
                <div class="sticky top-4">
                    <ReferenceList 
                        :icon="Asterisk" 
                        class="law-source-ref" 
                        :show="progress > 1" 
                        title="Referenties"
                        :sources="refList"
                        :status="run?.status" 
                    />
                </div>
            </div>
        </div>
            
    </div>
</template>

<style scoped>
.sticky {
    position: sticky;
    max-height: calc(100vh); /* Adjust this value as needed */
    overflow-y: auto;
}

@media (max-width: 768px) {
    .reference-list-hidden-mobile {
        display: none;
    }
}
</style>