import { defineStore } from 'pinia';
import { useAuth } from '@/auth';
import { ref } from 'vue';
import { io } from 'socket.io-client'
import { ModelRun } from '@/models'
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api'
import { useSubscriptionStore } from '@/stores/subscription';

export const useSocketStore = defineStore('socket', () => {
    const { getAccessToken } = useAuth()
    const threadSocket = ref(null)
    const runRepo = useAxiosRepo(ModelRun).setAxios(api)
    const subscription = useSubscriptionStore()


    async function connectThreadSocket() {
        threadSocket.value = io(
            process.env.VUE_APP_API_URL, {
                path: '/ws/threads',
                auth: {
                    token: await getAccessToken()
                },
                withCredentials: true
            }
        )
        threadSocket.value.on('run', async (data) => {
            if (data.data) {
                runRepo.save(data.data)
            }
            if (data.error) {
                data.data.status = 'error'
                runRepo.save(data.data)
                throw new Error(data.error)
            }
            if (data.data?.status !== 'running') {
                await subscription.fetchUsage();
            }
        })
        threadSocket.value.on('disconnect', (error) => {
            threadSocket.value = null
            throw new Error(error)
        })
    }

    async function connect() {
        if (!threadSocket.value) await connectThreadSocket()
    }

    return {
        threadSocket,
        connect
    }
});