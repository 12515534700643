<script setup>
import { useMagicKeys } from '@vueuse/core'
import { Button } from '@/components/ui/button'
import { Plus } from 'lucide-vue-next';
import { watchEffect } from 'vue'
import { useSidebarStore } from '@/stores/sidebar';
const { cmd, k } = useMagicKeys();

watchEffect(() => {
    if (cmd.value && k.value) {
        sidebarStore.pushRouteWithSidebarToggle('home')
    }
})

const sidebarStore = useSidebarStore();
</script>

<template>
    <div class="py-2 items-left text-primary/70">
        <Button @click="sidebarStore.pushRouteWithSidebarToggle('home')" variant="outline"
            class="w-full justify-center space-x-2 border border-border rounded-full">
            <Plus class="w-4 h-4" />
            <div class="flex flex-row items-end">
                <p class="text-sm">Onderzoek</p>
            </div> 
        </Button>
    </div>
</template>
