<script setup>
import ParalegalSelector from '@/components/searchbar/ParalegalSelector.vue';
import { SearchExamples } from '@/components/searchbar/';
import { onMounted, defineProps, toRefs, ref, inject } from 'vue';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { useSearch } from '@/composables';
import Upgrade from '@/components/Upgrade';
import { Upload } from '@/components/documents/';
import { usePosthog } from '@/composables/analytics';
const { identifyUser } = usePosthog();
const posthog = inject('posthog');

const props = defineProps(['ctx', 'id'])
const { ctx, id } = toRefs(props)
const { paralegalId, text, sendMessage } = useSearch(ctx.value, id.value)
const open = ref(false)
const isMobile = ref(false)

async function send() {
    try {
        await sendMessage();
        
    } catch (e) {
        if (e.message === 'Out of credits') {
            identifyUser()
            posthog.capture('$user_out_of_credits', { thread_id: id?.value })
            open.value = true;
        }
    }
}

async function send_example(example) {
    text.value = example;
    send();
}

function handleEnter(event) {
    if (!event.shiftKey) {
        event.preventDefault();
        send();
    }
}

function checkMobile() {
    isMobile.value = window.innerWidth <= 768; // Adjust this value as needed
}

onMounted(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
})

</script>

<template>
    <div class="p-3 w-full flex flex-col items-center bg-backgroundSecondary border mt-6 rounded-lg mb-2 ">
        <div class="mx-4 h-full w-full border rounded-lg flex-grow">
            <Textarea class="min-h-[100px] rounded-t-lg dark:bg-backgroundSecondary bg-white text-primary/90 dark:text-primary large-textarea-search-ref" id="searchbar-large-textarea" v-model="text"
                @keydown.enter="handleEnter"
            />
            <div class="flex flex-row items-center justify-between rounded-b-lg h-full p-2 dark:bg-backgroundSecondary bg-background border-border w-full">
                <div class="flex flex-row space-x-1 items-center">
                    <div v-if="ctx !== 'thread'" class="flex flex-row items-center space-x-1 select-paralegal-ref">
                        <ParalegalSelector v-model="paralegalId" />
                    </div>
                    <Upload class="hidden" v-show="!isMobile" />
                </div>
                <Button @click="send" variant="default" class="h-full h-10 items-center">
                    <i class='bx bxs-send'></i>
                </Button>
            </div>
        </div>
    </div>
    <p class="text-muted-foreground mx-2 text-xs mb-4 mx-auto w-full mt-4">
        Zeno kan fouten maken. Verifieer daarom altijd belangrijke informatie. Antwoorden van Zeno zijn informatief en niet bedoeld als juridisch advies.
    </p>
    <div class="w-[120%] upload-hidden-mobile relative">
            <SearchExamples 
                class="mt-4 w-full absolute left-[-10%]"
                @send-example="send_example"
            />
        </div>
    <Upgrade :share="true" v-model="open" :no_button="true" />
</template>

<style scoped>
#searchbar-large-textarea {
    resize: none;
}

@media (max-width: 768px) {
    .upload-hidden-mobile {
        display: none;
    }
}
</style>